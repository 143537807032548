import React, { useState } from "react"
import { get } from "lodash"
import { useParams } from '@reach/router';
import ComponentLayout from "../../../../ComponentLayout"
import NewDocumentBtn from "../../../../property/components/newDocument"
import ActionSkeletonWithMore from "../../../../actions/actionSkeletonWithMore"
import { canShowNewDocumentBtn } from "../../../../property/components/newDocument/utils"
import TextField from "@mui/material/TextField"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import InputAdornment from "@mui/material/InputAdornment"
import AccountCircle from "@mui/icons-material/Search"
import ListItem from "./list"
import { useGetUserDocumentsQuery } from "../../../../../../redux/services/property"
import { isAuthenticated } from "../../../../../../services/store/utils"
import IntroText from "../tabIntro";
import DocumentContent from "./content"
import { DocumentBox } from "../../../../StyleComp";
import { Grid } from "@mui/material";
import SectionTitle from "../sectionTitle";
import myaccountData from '../../../../../../../../static/data/myaccount.json';

const Content = props => {
    const authUser = isAuthenticated()
    const urlParams = useParams()
    const tabName = urlParams?.tab;
    const {
        data: documents,
        error,
        isError,
        isLoading,
        isFetching,
    } = useGetUserDocumentsQuery(
        {},
        { skip: !authUser }
    )

    const [filter, setFilter] = useState({
        query: "",
        type: false,
    })

    const data = documents || []

    const noRecord = !isLoading && !data.length
    const handleChange = (type, e) => {
        setFilter({
            ...filter,
            [type]: e.target.value,
        })
    }

    const collectDocTypes = data => {
        return [...new Set(data.map(item => item.type))]
    }

    return(
        <DocumentBox>
            <Grid container spacing={1}>
                <Grid item xs={12} className="Sanmugam">
                    <SectionTitle
                        {...props}
                        title={`Documents`}
                        tabsDescription={'documents'}
                        // actions={['newdocuemnt', 'contactus']}
                    />
                </Grid>
                <Grid item xs={12}>
                    <div className="content">
                        <DocumentContent
                            documents={documents}
                            property={{}}
                            propertyImage={``}
                            error={error}
                            isError={isError}
                            isLoading={isLoading}
                            isFetching={isFetching}
                            collectDocTypes={collectDocTypes(data)}
                            altClassName={'user-doc'}
                        />
                    </div>
                </Grid>
            </Grid>
        </DocumentBox>
    )
}

const ContentWrap = props => {
    return (
        <ComponentLayout>
            <Content {...props} />
        </ComponentLayout>
    )
}

export default ContentWrap
