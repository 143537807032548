import React, { useMemo, useState } from "react";
// import Button from "@mui/material/Button"
import { Pagination as MuiPagination } from '@mui/material';
import Stack from '@mui/material/Stack';
import { padding, styled } from '@mui/system';

// PaginationComponent.jsx
// import React, { useState } from 'react';
import { Box, Button, Typography, Select, MenuItem, FormControl, InputLabel, Container, Grid } from '@mui/material';
import { DropDownIcon } from "../icons";


const PAGE_SIZE = 10;
const USE_LOAD_MORE = false;
const TRIGGER_ON_DATA_CHANGE = true;

const BoxWrap = styled(Box)(({ theme }) => ({
  width: '100%',
  "& .select-input": {
    "& .MuiSelect-select": {
      paddingRight: "10px !important",
      width: "auto",
    },
    "& svg": {
      marginRight: "5px",
      height: "16px",
      width: "16px"
    },
    
  },
  "& .pagination-wrap": {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '8px',
    width: "100%",
    "& .pagination-page": {
      display: 'flex',
      alignItems: 'center'
    },
    [theme.breakpoints.down('md')]: {
      "& .MuiButtonBase-root": {
        padding: 0,
        margin: 0,
        width: "auto"
      }
    }
  },
  
}));

export const calculatePagesCount = (totalCount, pageSize=PAGE_SIZE) => {
    // we suppose that if we have 0 items we want 1 empty page
    return totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize);
};

export const usePagination = (props) => {
    const {data, currentPage, pageSize=PAGE_SIZE, useLoadMore=USE_LOAD_MORE, triggerDataChange=TRIGGER_ON_DATA_CHANGE} = props;

    return useMemo(() => {
        const firstPageIndex = (currentPage - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        const startIndex = useLoadMore ? 0 : firstPageIndex;
        return data.slice(startIndex, lastPageIndex);
      }, [currentPage, (triggerDataChange ? data : triggerDataChange)]);
}

export const Pagination = (props) => {
    const {pageCount, currentPage, setCurrentPage, useLoadMore=USE_LOAD_MORE} = props
    const marginTop = props?.marginTop || 0;
    // const [loading, setLoading] = useState(false)
    const handleloadmoreChange = () => {
        // setLoading(true)
        if (pageCount !== currentPage) {
            setCurrentPage(currentPage+1)
        }
    }

    const handlePaginationChange = (event) => {
        if (currentPage !== event.target.value)
            setCurrentPage(event.target.value)
    }

    // const [page, setPage] = useState(1);

    const handleBack = () => {
      setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };
  
    const handleNext = () => {
      setCurrentPage((prevPage) => Math.min(prevPage + 1, pageCount));
    };
  
    // const handlePageChange = (event) => {
    //   setPage(event.target.value);
    // };
  

    return (
        <BoxWrap className='pagination-load-more' style={{marginTop: `${marginTop}px`}}>
            {(pageCount > 1) && (
                <Stack alignItems="center">
                    {(useLoadMore && pageCount !== currentPage) && (
                        <Button
                            // disabled={loading}
                            variant="contained"
                            onClick={() => handleloadmoreChange()}
                        >
                                Load more
                        </Button>
                    )}

                    {!useLoadMore && (
                        // <MuiPagination
                        //     onChange={handlePaginationChange}
                        //     count={pageCount}
                        //     page={currentPage}
                        //     // variant="outlined"
                        //     shape="rounded"
                        //     color={`primary`}
                        // />
                        <Box
                          className="pagination-wrap"
                        >
                        <Button variant="text" color="primary" className="pagination-prev" onClick={handleBack} disabled={currentPage === 1}>
                          Back
                        </Button>
                        <Box className="pagination-page">
                          <Typography className="page-text1" variant="body1" sx={{ marginRight: 2 }}>
                            Page
                          </Typography>
                          <Box variant="outlined" size="small">
                            {/* <InputLabel>Page</InputLabel> */}
                            <Select
                              className="select-input"
                              value={currentPage}
                              onChange={handlePaginationChange}
                              label=""
                              IconComponent={DropDownIcon}
                              sx={{}}
                            >
                              {Array.from({ length: pageCount }, (_, i) => (
                                <MenuItem key={i + 1} value={i + 1}>
                                  {i + 1}
                                </MenuItem>
                              ))}
                            </Select>
                          </Box>
                          <Typography className="page-text2" variant="body1" sx={{ marginLeft: 2 }}>
                           of {pageCount}
                          </Typography>
                        </Box>
                        <Button className="pagination-next" variant="text" color="primary" onClick={handleNext} disabled={currentPage === pageCount}>
                          Next
                        </Button>
                      </Box>
                    )}
                </Stack>
            )}
        </BoxWrap>
    )
}

export const ComponentWithPagination = ({component:Component, filterComp:FilterComp, filterCompProps, data=[], marginTop, ...rest}) => {

    const useLoadMore = rest.hasOwnProperty('useLoadMore') ? rest.useLoadMore : true;
    const pageSize = rest.hasOwnProperty('pageSize') ? rest.pageSize : PAGE_SIZE;
    const [currentPage, setCurrentPage] = React.useState(1);
    const pageCount = calculatePagesCount(data.length, pageSize);
    const showPagination = pageCount > 1
    const pageData = usePagination({
        data: data,
        currentPage:currentPage,
        useLoadMore:useLoadMore,
        pageSize:pageSize
    })

    return(
        <>
          {showPagination && (
            <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
          {/* <Typography className="pagenation-show-text">Showing {PAGE_SIZE *  (currentPage - 1)} - {PAGE_SIZE *  (currentPage)} of {data.length} items</Typography> */}
            {/* {FilterComp && ( */}
              {/* <FilterComp {...filterCompProps} /> */}
            {/* )} */}
        </Box>
          )}

            <Component {...rest} data={pageData} />
            {showPagination && (
            <Pagination
                marginTop={marginTop}
                pageCount={pageCount}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                useLoadMore={useLoadMore}
                component
            />
            )}
            {/* <PaginationComponent pageCount={10}/> */}
        </>
    )
}




const PaginationComponent = ({ pageCount }) => {
  const [page, setPage] = useState(1);

  const handleBack = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNext = () => {
    setPage((prevPage) => Math.min(prevPage + 1, pageCount));
  };

  const handlePageChange = (event) => {
    setPage(event.target.value);
  };

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 2,
          // border: '1px solid #ccc',
          borderRadius: '8px',
          // boxShadow: '0px 0px 1px 0px rgba(0, 0, 0, 0.20), 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 2px 4px 0px rgba(0, 0, 0, 0.06)',
        }}
      >
        <Button variant="contained" color="primary" onClick={handleBack} disabled={page === 1}>
          Back
        </Button>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1" sx={{ marginRight: 2 }}>
            Page {page} of {pageCount}
          </Typography>
          <FormControl variant="outlined" size="small">
            <InputLabel>Page</InputLabel>
            <Select value={page} onChange={handlePageChange} label="Page">
              {Array.from({ length: pageCount }, (_, i) => (
                <MenuItem key={i + 1} value={i + 1}>
                  {i + 1}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography variant="body1" sx={{ marginLeft: 2 }}>
            Total Stages: {pageCount}
          </Typography>
        </Box>
        <Button variant="contained" color="primary" onClick={handleNext} disabled={page === pageCount}>
          Next
        </Button>
      </Box>
    </Container>
  );
};
