import React, { memo } from "react"
import NoActions from "../../../../progress/NoActions"
import Action from "../../../../actions/action/document"
import { FormStoreProvider } from "../../../../../../services"
import ComponentLayout from "../../../../ComponentLayout"
import { ComponentWithPagination } from "../../../../pagination"
import NewList from "./newList"

const ListItem = ({ data, image, ...rest }) => {
    return (
        <>
            {data.map(item => {
                return (
                    <div id={item.id} key={item.id}>
                        <FormStoreProvider>
                            <Action
                                data={{ ...item, image: image }}
                                {...rest}
                            />
                        </FormStoreProvider>
                    </div>
                )
            })}
        </>
    )
}

const List = ({ noRecord, data, filter, title, filterData, setFilterData, ...rest }) => {
    // const filter_Data = data.filter(d => {
    //     let filter_data = (
    //         (d.name || "")
    //             .toLowerCase()
    //             .includes((filter.query || "").toLowerCase()) &&
    //         (!filter.type || filter.type == 'all' || d.type == filter.type)
    //     )
    //     setFilterData(filter_data);
    //     return filter_data
    // })

    const noResult = noRecord || !filterData.length

    return (
        <>
            {noResult && (
                <NoActions
                    title={`There are no ${title}`}
                    icon="documentsIcon"
                />
            )}
            {!noResult && (
                <>
                    {/* <ComponentLayout title={`${title} (${filterData.length})`}> */}
                        <ComponentWithPagination
                            {...rest}
                            // component={ListItem}
                            component={NewList}
                            data={filterData}
                            marginTop={25}
                            useLoadMore={false}
                        />
                    {/* </ComponentLayout> */}
                </>
            )}
        </>
    )
}

export default memo(List)
