import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Grid, Card, CardContent, Typography, Link, Icon, IconButton, Box } from '@mui/material';
import { downloadDocument } from '../../../../../../services/store/api';
import { getUserRoleViaUrl, trackDocumentDownload } from '../../../../helper/eventTracking';
import { getUserRole } from '../../../../helper/action';
import { LinkItem } from '../../../../helper/myaccountLink';
import StarberryIcons from '../../../../icons';

const DownloadBtn = ({ data }) => {

    const [isDownloading, setIsDownloading] = React.useState(false)

    let labelText = "Download"
    if (isDownloading) labelText = `Preparing...`;
    let userRole = getUserRole(/*property*/{}, data);
    if (!userRole && typeof window !== 'undefined') {
        userRole = getUserRoleViaUrl(window.location.pathname)
    }
      // Create blob link to download
    const doDownload = (data, blob) => {
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement("a")
        let fileName = data.name;
        const docType = data.type;
        if (fileName.indexOf('.') === -1) {
            let tmp = data.attachment_url.split('.')
            if (tmp[1])
                fileName = `${fileName}.${tmp[1]}`
        }

        link.href = url
        link.setAttribute("download", fileName)
        link.setAttribute("target", "_blank")

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
        setIsDownloading(false)

        trackDocumentDownload({eventType:docType, eventRole:userRole})

    }

    const onSubmit = (event, data) => {
        event.preventDefault()
        setIsDownloading(true);
        downloadDocument({'document_id': data.crm_id})
            .then((res) => {
                if (res.data) {
                    doDownload(data, res.data)
                } else {
                    labelText = 'File not Found!';
                    setIsDownloading(false)
                }
            }
        )
    }

  return (
    <>
        <Link className="action-btn" key={'i'} href={"#"} onClick={event => onSubmit(event, data)} underline="none" style={{ marginRight: '8px', display: 'inline-flex', alignItems: 'center' }}>
            <StarberryIcons iconName={"customOtherIcon"} sx={{ verticalAlign: 'middle', marginRight: '8px' }}/>
            {labelText}
        </Link>
    </>
  )
}

export default DownloadBtn;
