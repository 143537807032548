export default {
    props: {
        btnLabelProps: "Make an Offer",
        offerFormTerms: false,
        tenancyOfferFormTerms: false,
        useTenancyOfferLink: false,
        btnProps: {
            color: "primary",
            variant: "contained",
            sx: theme => ({
                width: "100%",
                marginBottom: theme.spacing(2),
                [theme.breakpoints.up("md")]: {
                    width: "100%",
                },
                "&.btn-add": {
                    maxWidth: "300px",
                    marginBottom: theme.spacing(2),
                },
                "& .sm-visible": {
                    display: "inline-block",
                    [theme.breakpoints.up("sm")]: {
                        display: "none",
                    },
                },
                "& .md-visible": {
                    display: "none",
                    [theme.breakpoints.up("sm")]: {
                        display: "inline-block",
                    },
                    [theme.breakpoints.up("md")]: {
                        display: "none",
                    },
                },
                "& .lg-visible": {
                    display: "none",
                    [theme.breakpoints.up("md")]: {
                        display: "inline-block",
                    },
                },
                "& .btn-with-link": {
                    color: "inherit",
                    "&:hover": {
                        textDecoration: "none",
                    },
                },
            }),
        },
    },
}
