import React from "react";
import { withSubtheme } from '../../../../../StarberryComponentsMui';
import GlobalForm from "../../../forms/";
import { collectFormValues, validateFormFields, isRequiredFieldHasValues } from "../../../forms/utils";
import { useFormState } from "../../../../../services";
import reportFaultFields from "../../../forms/Forms/bookViewingCustom";

import defaults from "../reportFaultBtn/dialogChildrenDefaults";

const DialogChildren = withSubtheme((props) => {
    const { className } = props

    const { state, services } = useFormState()
    let customFields = [...reportFaultFields];

    const [formFields, setFormFields ] = React.useState([...customFields]);

    const ref = React.useRef(null)
    const formStateType = {}
    const messageDialog = { message:state.message, severity:state.error ? 'error' : 'success'}
    const hideForm = (state.success && !state.error)
    const formData = { submitLabel: "Submit" }

    const onSubmit = event => {
        event.preventDefault()


        const formWithValue = validateFormFields(ref, customFields)
        setFormFields(formWithValue);
        // inputRef
        if (ref.current.checkValidity() !== false && isRequiredFieldHasValues(formWithValue)) {
            const params = {
                property_id: props?.property?.crm_id,
                form_name: "viewing",
                type_id: "VW",
                negotiator_id: props?.property?.negotiator_id,
                // step: step,
                successMessage: "Thank you for your viewing request. A member of our team will be in touch shortly.",
                // ...actionParams.formParams,
            }
            const values = collectFormValues(ref, params)
            services.postForm(values)
        } else {
            // @todo
        }
    }

    return(
        <div className={className}>
            <GlobalForm
                formFields={formFields}
                handleSubmit={onSubmit}
                formStateType={formStateType}
                formState={state}
                messageDialog={messageDialog}
                data={formData}
                ref={ref}
                hideForm={hideForm}
            />
        </div>
    )
}, 'dialogChildren', defaults)

export default DialogChildren
