import React from 'react';
import { withSubtheme } from '../../../StarberryComponentsMui';
import {Grid, Container} from '@mui/material';
import GlobalLayout from '../Layout';
import { useAuthState } from '../../../services';
import PageTitle from '../pageTitle';
import UserDocument from './my-property/common/document/userDocument';


const DocumentInner = (props) => {
    const {theme, children, className} = props;
    const { services:authServices } = useAuthState();
    const pageTitle = `Documents`;

    React.useEffect(() => {
        authServices.updateState({pageTitle:pageTitle, pageRightCustomComponent: null, pageRightDrawer: null, pageLeftIcon: false});
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return(
         <Container maxWidth="xl">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    {/* <PageTitle theme={theme} component="h1" variant="h3">{pageTitle}</PageTitle> */}
                    <UserDocument />
                </Grid>
            </Grid>
        </Container>
    )
}

const Document = withSubtheme((props) => {
    return (
        <GlobalLayout>
            <DocumentInner {...props} />
        </GlobalLayout>
    );
}, 'Documentspage')

export default React.memo(Document);
