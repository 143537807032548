import React from 'react';
import { get } from "lodash";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Grid, Card, CardContent, Typography, Link, Icon, IconButton, Box, Divider } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';
import { display, fontSize, height, margin, padding, styled, width } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonIcon from '@mui/icons-material/Person';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import InfoIcon from '@mui/icons-material/Info';
import StarberryIcons from '../../../../icons';
import { displayData } from '../../../../helper/dateformat';
import { getUserRoleViaUrl, trackDocumentDownload } from '../../../../helper/eventTracking';
import { downloadDocument } from '../../../../../../services/store/api';
import { apiUrl } from '../../../../../../services';
import { formatPrice, getUserRole } from '../../../../helper/action';
import DownloadBtn from './downloadBtn';
import { ListActionsBoxWrap } from '../../../../StyleComp';
import { getDateDocument, getTitleDocument } from '../utils';

const ResponsiveTable = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { data } = props

  const [isDownloading, setIsDownloading] = React.useState(false)

  let labelText = "Download"
  if (isDownloading) labelText = `Preparing...`;
  let userRole = getUserRole(/*property*/{}, data);
  if (!userRole && typeof window !== 'undefined') {
      userRole = getUserRoleViaUrl(window.location.pathname)
  }

  return (
    <ListActionsBoxWrap>
      {!isMobile ? (
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Date</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor: index % 2 === 1 ? 'background.default' : 'white',
                  }}
                >
                  <TableCell>
                    <Box className='table-data'>
                      <StarberryIcons iconName="customDocumentsIcon" />
                      {getTitleDocument(row)}
                    </Box>
                  </TableCell>
                  <TableCell>
                    {row.type}
                  </TableCell>
                  <TableCell>
                    {displayData(getDateDocument(row))}
                  </TableCell>
                  <TableCell>
                    <DownloadBtn data={row} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Grid spacing={2}>
          {data.map((row, index) => (
            <Card className="action-card-item" key={index}>
              <Grid container spacing={2} alignItems="baseline">
              <Grid item className="action-icon">
                <Typography>.</Typography>
                  <StarberryIcons iconName="customDocumentsIcon" />
                </Grid>
                <Grid item xs>
                  <CardContent>
                    <Typography variant="h6">{row.title}</Typography>
                    <Typography className="info-text" variant="body2" color="textSecondary">
                      {row.type}
                      <Divider />
                      {displayData(row?.check_date || row?.document_created_date)}
                    </Typography>
                    {/* <Typography variant="body2" color="textSecondary">{row.type} {` . `} {displayData(row?.check_date || row?.document_created_date)}</Typography> */}
                      <DownloadBtn data={row} />
                  </CardContent>
                </Grid>
              </Grid>
            </Card>
          ))}
        </Grid>
      )}
    </ListActionsBoxWrap>
  );
};

export default ResponsiveTable;
