/* eslint-disable import/first */

import React from "react"
import { Router } from "@reach/router"

// Ref: https://www.gatsbyjs.com/docs/how-to/routing/client-only-routes-and-user-authentication/

// Importing individual components like this (rather than
// `import {...} from '@material-ui/core';` ) makes for more efficient
// bundling, in theory :
// see https://material-ui.com/guides/minimizing-bundle-size/
//import Typography from "@mui/material/Typography";
//import Grid from "@material-ui/core/Grid";

import { useTheme } from "../core/StarberryComponentsMui"
import {
    MYACCOUNT_DOCUMENTS,
} from "../core/constants/urls";

import PrivateRoute from "../core/components/MyAccount/hoc/PrivateRoute"
import Documents from "../core/components/MyAccount/pages/documents"
import PageHeaderHelmet from "../core/components/pageHeader";

const MyAccountPages = () => {

    const theme = useTheme()

    return (
        <>
            <PageHeaderHelmet theme={theme} title={`Documents`}/>
            <Router>
                <PrivateRoute
                    path={MYACCOUNT_DOCUMENTS}
                    component={Documents}
                    theme={theme}
                />
            </Router>
        </>
    )
}

export default MyAccountPages
