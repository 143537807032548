export default {
    props: {
        apiCallFun: "getAvailabilitySlots",
        customApiCall: {},
        btnLabelProps: `<span class="sm-visible">Viewing</span>
                                    <span class="md-visible">
                                        Book a Viewing
                                    </span>
                                    <span class="lg-visible">
                                        Book a Viewing
                                    </span>`,
        showBtnIcon: false,
        btnProps: {
            color: "primary",
            variant: "contained",
        },
    },
}
