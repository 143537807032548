import React, { useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import { display, margin, styled, width } from '@mui/system';
import { get } from "lodash"
import { useParams } from '@reach/router';
import ComponentLayout from "../../../../ComponentLayout"
import NewDocumentBtn from "../../../../property/components/newDocument"
import ActionSkeletonWithMore from "../../../../actions/actionSkeletonWithMore"
import { canShowNewDocumentBtn } from "../../../../property/components/newDocument/utils"
import TextField from "@mui/material/TextField"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import InputAdornment from "@mui/material/InputAdornment"
import AccountCircle from "@mui/icons-material/Search"
import ListItem from "./list"
import { useGetPropertyDocumentsQuery } from "./../../../../../../redux/services/property"
import { isAuthenticated } from "../../../../../../services/store/utils"
import IntroText from "../tabIntro";
import { Box, Typography } from "@mui/material";
import NewDocumentBtnWrap from "./newDocumentBtn";
import { Block } from "@mui/icons-material";
import { useQuery } from "../../../../utils";
import { FilterBox, FilterBoxWrap } from "../../../../StyleComp";
import { DropDownIcon } from "../../../../icons";

const FilterComp = ({collectDocTypes, data, handleChange, filter, ...rest}) => {
    return(
        <FilterBox>
        {/* {!!data.length && data.length > 5 && ( */}
            <>
                {/* <FormControl sx={{ m: 1, minWidth: 120 }} className="filter-select"> */}
                <div className={`filter-gropu-field ${rest?.altClassName}`}>
                <div class="filter-select-wrap">
                    {/* <InputLabel id="demo-select-small">Filter Type</InputLabel> */}
                    <b className="mobile-hidden">Filter:</b> 
                    <Select
                        className="filter-select"
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        // label="Type"
                        defaultValue={'all'}
                        onChange={e => handleChange("type", e)}
                        value={filter.type}
                        variant="outlined"
                        hiddenLabel={true}
                        label={''}
                        IconComponent={DropDownIcon}
                    >
                        <MenuItem value={'all'}>{`All Types`}</MenuItem>
                        {collectDocTypes.map(t => {
                            return <MenuItem value={t}>{t}</MenuItem>
                        })}
                    </Select>
                    </div>
                {/* </FormControl> */}
                <Box className="filter-new">
                    <NewDocumentBtnWrap {...rest} />
                </Box>
                </div>
                <FormControl sx={{ m: 0, minWidth: 120 }} className="filter-search">
                    <TextField
                        placeholder="Type document name..."
                        type="search"
                        defaultValue={filter.query}
                        onChange={e => handleChange("query", e)}
                        // label={`Filter by document name`}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircle />
                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                        hiddenLabel={true}
                        label={''}
                    />
                </FormControl>
            </>
        {/* )} */}
    </FilterBox>

    )
}

const Content = (props) => {
    const {collectDocTypes, documents, propertyImage, property, error, isError, isLoading, isFetching} = props
    const urlParams = useParams()
    const tabName = urlParams?.tab;
    const location = useLocation()
    const urlTypeData = useQuery(location, "type")
    const [filter, setFilter] = useState(() => {
        let initialVal = {
            query: "",
            type: "all",
        }

        // if (urlTypeData) {
        //     initialVal['type'] = urlTypeData
        // }

        return initialVal
    })
    const [filterData, setFilterData] = useState([])

    console.log('filter =>', filter, collectDocTypes, filterData)

    const data = documents || []
    // const tabsDescription = get(props, `${props?.persona}.tabsDescription.documents`, get(props, 'tabsDescription.documents', false))

    const noRecord = !isLoading && !data.length
    const handleChange = (type, e) => {
        setFilter({
            ...filter,
            [type]: e.target.value,
        })
    }

    // Remove filter if url type not available
    useEffect(() => {
        if (collectDocTypes.length) {
            if (collectDocTypes.includes(urlTypeData)) {
                setFilter({
                    ...filter,
                    type: urlTypeData
                })
            } else {
                const currentUrl = window.location.href;
                const url = new URL(currentUrl);
                url.search = '';
                window.history.replaceState(null, '', url);
            }
        }
      }, [collectDocTypes])

    // Load inintial data 
    useEffect(() => {
      if ((!filterData.length && documents && documents.length) || (documents && filterData && documents.length !== filterData.length)) {
        setFilterData(documents)
      }
    }, [documents])

    // Filter data
    useEffect(() => {
        const filterdata = data.filter(d => {
            let filter_data = (
                (d.name || "")
                    .toLowerCase()
                    .includes((filter.query || "").toLowerCase()) &&
                (!filter.type || filter.type == 'all' || d.type == filter.type)
            )

            return filter_data
        })
        setFilterData(filterdata);

    }, [filter])

    return (
        <>
            <FilterBoxWrap>
                {!!data.length && (
                    <Typography sx={{}} className="item-count">
                        {(data.length < 10) ? (
                            <>Showing {data.length} documents</>
                        ) : (
                            <>Showing {filterData.length} of {data.length} documents</>
                        )}
                    </Typography>
                )}
                <FilterComp
                    {...props}
                    collectDocTypes={collectDocTypes}
                    data={data}
                    handleChange={handleChange}
                    filter={filter}
                    alClassName={props?.altClassName}
                />
            </FilterBoxWrap>
            {isLoading && !data.length ? (
                <ActionSkeletonWithMore title />
            ) : (
                <ListItem
                    // filterComp={FilterComp}
                    // filterCompProps={{
                    //     collectDocTypes:collectDocTypes,
                    //     handleChange:handleChange,
                    //     data:data,
                    //     filter:filter
                    // }}
                    collectDocTypes={collectDocTypes}
                    handleChange={handleChange}
                    
                    noRecord={noRecord}
                    data={data}
                    propertyImage={propertyImage}
                    title={`Documents`}
                    filter={filter}
                    // tabsDescription={tabsDescription}
                    property={property}
                    tabName={tabName}
                    mobile={props?.mobile}
                    filterData={filterData}
                    setFilterData={setFilterData}
                />
            )}
        </>
    )
}

export default Content
